<template lang='pug'>
article.birthdays
  .birthdays__cont.cont
    h1.birthdays__caption.caption Дни рождения
    p.birthdays__lid
      | Хотите весело и вкусно отметить день рождения в формате мастер-класса?
      br
      | Музей «Шоколадный Выборг» с радостью поможет вам в этом!
    .birthdays__area
      .birthdays__box
        h2.birthdays__headline Вы узнаете
        p.birthdays__text
          | Историю шоколада, познакомитесь с секретами его приготовления и лично освоите кулинарные техники, которыми
          | владеют шоколатье
      .birthdays__box
        h2.birthdays__headline Вы получите
        p.birthdays__text
          | Шоколадную фигурку, приготовленную и украшенную вами, а также диплом «Юный шоколатье», который станет
          | приятным напоминанием об этом чудесном опыте
    h2.birthdays__title.birthdays__title_price.title Стоимость дня рождения в Музее
    span.birthdays__text-big.text-big
      | Стоимость — 1500 ₽/чел
    span.birthdays__text-big.text-big
      | За дополнительную плату возможно:
    ul.birthdays__list
      li.birthdays__item.text-big украшение комнаты воздушными шарами
      li.birthdays__item.text-big шоколадный фонтан
      li.birthdays__item.text-big аниматоры от музея
      li.birthdays__item.text-big торт на заказ от музея
      li.birthdays__item.text-big увлекательный квест по территории парка
    event-contact(:title="'Предварительная запись обязательна'", :text="'Узнайте о новых мероприятиях и запишитесь по телефону:'")
    event-gallery(
      :images="images",
      headline="Мастер-класс для детей",
      text="Ребятам рассказали про шоколад, как правильно темперировать его и заливать в формы. Они сделали себе фигурки, за что получили диплом юного шоколатье")
</template>

<script>
import '@/assets/styles/components/birthdays.sass'
import EventContact from '../EventContact'
import EventGallery from '../EventGallery'
export default {
  name: 'TheBirthdays',
  components: { EventGallery, EventContact },
  data () {
    return {
      images: [
        { path: 'images/photo-gallery/photo-gallery-5', alt: 'Юные шоколатье в Музее «Шоколадный Выборг»  фото', mod: 'small' },
        { path: 'images/photo-gallery/photo-gallery-6', alt: 'Мероприятия в Музее «Шоколадный Выборг»  фото', mod: 'small' }
      ]
    }
  }
}
</script>
